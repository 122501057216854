(function($) {
  'use strict';

  function getCookie (name) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  $(document).ready(function(){

    if ($('#carttotal').length) {
      let cartTotalUrl = $('#carttotal').data('url');
 
      fetch(cartTotalUrl).then(function (response) {
        // The API call was successful!
        return response.text();
      }).then(function (jsonCart) {
        const store = JSON.parse(jsonCart);
        let cartLink = '';

        if (parseInt(store.cart.qty) > 0) {
          cartLink = '<a href="'+store.cart.url+'">Your Cart: '+store.cart.qty+' items ('+store.cart.subtotal+')</a>';
        }

        $('#carttotal').html(cartLink);
      }).catch(function (err) {
        // There was an error
        console.warn('Unable to retrieve Cart details from Store URL: ' + cartTotalUrl, err);
      });
    }

    var idleTime = 0;
    var idleInterval;
    if (Modernizr.touch) { 
      $('.primary-nav .nav-item > a').on('touchend', function(evt){
        if ($(this).hasClass('clickable')) {
          return true;
        }
        if ($(this).next().hasClass('nav-secondary')) {
          $(this).parent().addClass('show-secondary');

          $(this).addClass('clickable');

          //Increment the idle time counter every minute.
          idleInterval = setInterval(timerIncrement, 7000); 
        }

        return false;
      });

      $('.primary-nav .nav-item.nav-item--has-children > a').on('touchend', function(evt){
        if ($(this).parent().hasClass('show-quaternary')) {
          window.location = $(this).attr('href');
        } 

        $(this).closest('.nav-tertiary').find('.show-quaternary').removeClass('show-quaternary');
        $(this).parent().addClass('show-quaternary');

        return false;
      });

      //Zero the idle timer on mouse movement.
      $(this).mousemove(function (e) {
        idleTime = 0;
      });
      $(this).keypress(function (e) {
        idleTime = 0;
      });
      //Zero the idle timer on touch events.
      $(this).bind('touchstart', function(){
       idleTime = 0;
      });
      $(this).bind('touchmove', function(){
       idleTime = 0;
      });

      $(window).scroll(function() {
        if ($('.primary-nav').find('.nav-item.show-secondary').length) {
          if($(window).scrollTop() > 500){
            hideNav();
          }
        }
      });

      function hideNav() {
        $('.primary-nav .show-secondary').each(function(){
          $(this).removeClass('show-secondary');
        });

        $('.primary-nav .clickable').each(function(){
          $(this).removeClass('clickable');
        });

        $('.primary-nav .show-quaternary').each(function(){
          $(this).removeClass('show-quaternary');
        });

        // End interval
        window.clearInterval(idleInterval);
      }

      function timerIncrement() {
        idleTime = idleTime + 1;
        if (idleTime > 1) {
          hideNav();
        }
      }

      $('body').on('touchend', function(e){
        if (!$(e.target).closest(".nav-secondary").length) {
          hideNav();
        }
      });
    }

    // Mobile Exapnding Navigation
    $('.nav--mobile .nav-item.has-children').on('click touchend', function(evt){
      evt.stopPropagation();

      // Make sure element can be toggled
      if ($(event.target).parent().hasClass('has-children')) {
        $(this).children('.nav-nested').slideToggle();
        $(this).toggleClass('expanded');
      } else if (event.target.tagName == "A") {
        return true;
      } 

      evt.preventDefault();
    });

    // Nested Quaternary nav positioning offset
    function navQuaternaryPositioning() {
      /* 
       * This only impacts the first column in the current design so 
       * in an attempt to optimize the JS I'm targetting that specific 
       * column's child elements.
       */

      var $nav = $('.primary-nav .column:first-of-type');

      if ($nav.length) {
        var $navContainer = $nav.closest('.nav-secondary');
        var navHeight = $navContainer.height();
        var navTop = $navContainer.offset().top;
        var navBottom = navTop + navHeight;

        var $tertiaryNav = $nav.find('.nav-tertiary');
        var tertiaryNavTop = $tertiaryNav.offset().top;
        var tertiaryNavBottom = tertiaryNavTop + $tertiaryNav.height();

        // Get nested quaternary nav elements
        var $quaternaryNavs = $nav.find('.nav-item--has-children');

        $.each($quaternaryNavs, function(i, el){
          var $childNav = $(this).find('.nav-quaternary');
          var childNavTop = $childNav.offset().top;
          var childNavBottom = childNavTop + $childNav.height();
          var topOffset = 0;

          if (childNavBottom > tertiaryNavBottom) {
            topOffset = tertiaryNavBottom - childNavBottom;
            var overExtendedTop = tertiaryNavTop - (childNavTop + topOffset);

            // is there room to simply move it up?
            if ( overExtendedTop > 0 ) {
              topOffset = topOffset + overExtendedTop;
            }
          }

          if (topOffset != 0) {
            $childNav.css('top', topOffset + 'px');

            // Does container need to be extended?
            childNavBottom = $childNav.offset().top + $childNav.height() + topOffset;

            if (childNavBottom > navBottom) {
              $navContainer.css('min-height', (navHeight + (childNavBottom - navBottom) + 30) + 'px');

              // Update nav globals
              navBottom = navTop + $navContainer.innerHeight();
              navHeight = $navContainer.innerHeight();

            } else {
              $navContainer.removeAttr('style');
            }
          }
          else {
            $childNav.removeAttr('style');
          }
        });
      }
    }

    // Only run for desktop screens
    if ($(window).width() >= 841){
      navQuaternaryPositioning();
    }

    // On window resize, if greater or euqal to 841
    $(window).resize(function () {
      if ($( window ).width() >= 841){
        navQuaternaryPositioning();
      }
    });
  });
})(jQuery);