(function($) {
    'use strict';
    $(document).ready(function(){
        const $dispalyedPrice = $('.product-details .product-price');
        const basePrices = $dispalyedPrice.data('base-prices');
        let type = false;
        
        if ($('body.product-detail-page').length) {
            let sessionPricing = false;
            if ($dispalyedPrice.data('base-prices')) {
                sessionPricing = true;
            }

            $('.stepDown').on('click', function(){
                $(this).parent().find('input')[0].stepDown(1);
            });

            $('.stepUp').on('click', function(){
                $(this).parent().find('input')[0].stepUp(1);
            });

            if ($('.product-format').length) {        
                $('.product-format input[type="radio"]').on('change', function(){
                    type = $(this).data('type');
                    $('.product-options select option:selected').prop('selected', false);
                    $('.product-options select:not(.'+type+')').addClass('hidden');
                    $('.product-options select.' + type).removeClass('hidden');

                    if (sessionPricing) {
                        // Update Base Price...
                        $dispalyedPrice.html($dispalyedPrice.data('base-prefix')+'<span>' + basePrices[type] + '</span>');
                    }
                });

                if ($('.product-options select option[value!=""]:selected').length) {
                    const formUrl = $('.product-options select option[value!=""]:selected')[0].value;
                    $('form#add-to-cart').attr('action', formUrl);
                }

                $('.product-options select').on('change', function(){
                    type = $('input[name="format"]:checked').data('type');
                    let sessionId = $(this).find('option:selected').data('id');
                    const formUrl = $(this).find('option:selected')[0].value;
                    $('form#add-to-cart').attr('action', formUrl);
                    $('.session-modal').addClass('hidden');
                    let $details = $('#session' + sessionId);
                    if ($details.length) {
                        $details.removeClass('hidden');
                    }

                    $('.course-session-footnotes div[data-id]').each(function(e){ $(this).addClass('hidden'); });
                    $('.course-session-footnotes div[data-id="'+sessionId+'"]').removeClass('hidden');

                    console.log('formUrl', formUrl.length);
                    console.log('type', type);

                    // if (formUrl) {
                    //     sessionPricing = true;
                    // }

                    if (sessionPricing) {
                        // Show Session Price...
                        if (sessionId) {
                            $dispalyedPrice.html($dispalyedPrice.data('prefix')+'<span>'+$(this).find('option:selected').data('price')+'</span>');
                        } else {
                            $dispalyedPrice.html($dispalyedPrice.data('base-prefix')+'<span>' + basePrices[type] + '</span>');
                        }
                    }
                });
            }
        }
    });
})(jQuery);