(function($) {
  'use strict';
  $(document).ready(function(){
    var $currentYear = $('.schedule-year-selector li.active');

    if ($currentYear.prev()) {
      $currentYear.prev().addClass('previous-year');
    }

    if ($currentYear.next()) {
      $currentYear.next().addClass('next-year');
    }
  });
})(jQuery);