(function($) {
  'use strict';

  const $tabs = $('.tabs');

  $(document).ready(function(){
    if ($tabs.length) {
      init();

      $('button').on('click', function(){
        const tab = $(this).data('target');
        if (!$(this).hasClass('active')) {
          loadTab(tab);
        }
      });

      // Arrow key navigation through tabs
      $(document).keydown(function(e){
          var arrow = { left: 37, right: 39 };

          switch(e.which) {
              case arrow.left:
                moveTo('prev');
              break;

              case arrow.right:
                moveTo('next');
              break;

              default: return; // allow other keys to be handled
          }

          // prevent default action (eg. page moving up/down)
          // but consider accessibility (eg. user may want to use keys to choose a radio button)
          e.preventDefault();
      });
    }
  });

  $(window).on('hashchange', function(){
    if ($tabs.length) {
      init();
    }
  });

  function init() {
    // By default, load first tab
    let tab = $('.tabs button.active').data('target');
    if (!tab) {
      tab = $('.tabs button').first().data('target');
    }

    // Does hash exist and does it match a tab ID?
    if (window.location.hash) {
      let hash = window.location.hash.replace('#', '');
      if (hash.indexOf('&')) {
        const hashes = hash.split('&');
        for (var i = 0; i < hashes.length; i++) {
          if (hashes[i].indexOf('=') == -1) {
            hash = hashes[i];
          }
        }
      }

      if ($('.tabs button[data-target="'+hash+'"]').length) {
        if (hash != tab) {
          tab = hash;          
        }
      }
    }

    // Trigger load tab
    loadTab(tab);
  }

  function loadTab(tab) {
      const $content = $('.tab-content');
      $content.each(function(i, el){
        $(el).css('display', 'none').attr('tabindex', "-1");
      });

      const $buttons = $('.tabs button');
      $buttons.removeClass('active').attr("aria-selected", "false");

      // Show Tab
      $('#'+tab).attr('tabindex', "0").show();
      $('.tabs button[data-target="'+tab+'"]').addClass('active').attr("aria-selected", "true");

      // Update Browser history and URL hash
      updateHistoryAndHash(tab);
  }

  function moveTo(dir) {
    const $active = $('.tabs button.active');
    let tab = $active.next().data('target');

    if (dir == 'prev') {
      tab = $active.prev().data('target');      
    }

    if (tab !== undefined) {
      loadTab(tab);
    }
  }

  function updateHistoryAndHash(tabHash) {
    // Build URL
    let url = window.location.href.split('#')[0];

    // Get Hash
    const hash = window.location.hash.replace('#', '');
    let urlHash = tabHash;
    if (hash.indexOf(tabHash) != -1 && hash.indexOf('&') != -1) {
      const hashes = hash.split('&');
      for (var i = 0; i < hashes.length; i++) {
        if (hashes[i].indexOf('=') != -1) {
          urlHash += '&' + hashes[i];
        }
      }
    }

    url = url + '#' + urlHash;

    // Update Title
    const tabHeading = $('.tabs button[data-target="'+tabHash+'"]').text();
    let title = document.title.split(' | ');
    title[0] += ' > ' + tabHeading;
    title = title.join(' | ');

    history.pushState({ id: tabHash }, title, url);
  }
})(jQuery);