(function($) {
  'use strict';
  $(document).ready(function(){
    $('.quotes').slick({
      arrows: true,
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      slide: '.quote',
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: '6000'
    });
  });
})(jQuery);