(function($) {
  'use strict';

  $(document).ready(function(){
  	const filterSelector = '[resource-filter]';

    function handleOnloadFiltering() {
      // Set default/placeholder text
      $(filterSelector).each(function(i, ele){
        var $selectLabel = $(ele).find('.filter-list-select');
        if ($selectLabel.html() === '') {
          var $active = $(ele).find('ul li a.active');
          var heading = $active.attr('data-title') ? $active.attr('data-title') : $active.html();
          $selectLabel.html(heading);
        }
      });
    }

    function handleFiltering() {
      let $filters = $(filterSelector);

      if ($filters.length) {
        $filters.each(function(index, element){
          
          $(document).on('click', filterSelector + ' ul li a', function(evt){
            if ($(this).hasClass('active')) {
              evt.preventDefault();
              evt.stopImmediatePropagation();
              return false;
            }
          });
        });
      }
    }

    if ($('main.resources').length) {
      handleOnloadFiltering();
      handleFiltering();
    }
  });
})(jQuery);