(function($) {
  'use strict';

  const activeClass = 'expanded';

  $(document).ready(function(){
    $('.accordion--handle').on('click', function(){
      $(this).closest('.accordion').toggleClass(activeClass).trigger("accordionToggled");
    });

    let $onload = $('.accordion.'+activeClass);
    if ($onload.length) {
      $onload.each(function(i, el){
        $(this).find('.accordion--content').slideDown();
      });
    }

    $('.accordion').bind('accordionToggled', function(ev) {
      if ($(this).hasClass(activeClass)) {
        $(this).find('.accordion--content').slideDown();
      } else {
        $(this).find('.accordion--content').slideUp();
      }
    });
  });

})(jQuery);