(function($) {
  'use strict';

  $(document).ready(function(){
    $('[href^="#"]').on('click', function(ev){
      const anchor = $(this).attr('href');

      const destination = $(anchor);

      // Fixed header offset
      const top = destination.offset().top;

      if (destination.length) {
        $('html,body').animate({
          scrollTop: top,
        }, 300);
      }

      ev.preventDefault();
      return false;
    });
  });

})(jQuery);