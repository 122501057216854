(function($) {
  'use strict';
  $(document).ready(function(){
    $(".hero a[href^='#']").on('click', function(evt){
      if ($(this).attr('href').length > 1) {
        var $target = $(''+$(this).attr('href')+'');
        $('html, body').animate({
            scrollTop: ($target.offset().top)
        },500);

        evt.preventDefault();
        return false;
      }
    });

    $('.jump-list, .filter-list').each(function(){
      var $wrapper = $(this);
      var $select = $wrapper.find('.jump-list-select, .filter-list-select');
      var $list = $wrapper.find('ul');

      $select.on('click', function(){
        $wrapper.toggleClass('expanded');
      });

      $wrapper.mouseleave(function(){
        if (!$list.is(':hover')) {
          $wrapper.removeClass('expanded');
          $wrapper.find('ul')[0].scrollTop = 0;
        }
      });
    });
  });
})(jQuery);