(function($) {
  'use strict';

  $(document).ready(function(){
    $('.course-path--wrapper li[data-toggle]').on('click', function(){
      $('.course-path--wrapper li.active').removeClass('active');

      $(this).addClass('active');

      let toggleId = $(this).data('toggle');
      let $accordion = $('#'+toggleId);

      if (!$accordion.hasClass('expanded')) {
        $('.accordions .accordion.expanded').find('.accordion--handle').click();
        setTimeout(function(){
          // console.log('triggered');
          // Simulate accordion click.
          $accordion.find('.accordion--handle').click();
          scrollTo($accordion);
        }, 350);
      } else {
        scrollTo($accordion);
      }

      updateHistoryAndHash(toggleId);

      // Scroll to accordion element
      function scrollTo($accordion) {
        // Scroll to accordion
        $('html,body').animate({
          scrollTop: $accordion.offset().top - 20,
        }, 350);
      }
    });


    function init() {
      if (window.location.hash.indexOf('c=') != -1) {
        const hashes = window.location.hash.replace('#', '').split('&');
        for (var i = 0; i < hashes.length; i++) {
          if (hashes[i].indexOf('c=') != -1) {
            const course = hashes[i].replace('c=', '');
            var $button = $('.course-path--wrapper li[data-toggle="'+course+'"]');
            if ($button.length) {
              $button.trigger('click');
            }
          }
        }
      } else {
        $('.course-path--wrapper li[data-toggle]').first().addClass('active');
        $('.accordions .accordion').first().addClass('expanded');
        $('.accordions .accordion').first().find('.accordion--content').show();
      }
    }
    init();

    function updateHistoryAndHash(courseHash) {
      // Build URL
      let url = window.location.href.split('#')[0];

      // Get Hash
      const hash = window.location.hash.replace('#', '');
      let urlHash = '';
      if (hash.indexOf('&') != -1) {
        const hashes = hash.split('&');
        for (var i = 0; i < hashes.length; i++) {
          if (hashes[i].indexOf('c=') == -1) {
            urlHash += (urlHash.length > 0 ? '&' : '') + hashes[i];
          }
        }
      }

      // Add Course Hash
      urlHash += (urlHash.length > 0 ? '&' : '') + 'c=' + courseHash;

      url = url + '#' + urlHash;

      // Update Title
      const tabHeading = $('.tabs button[data-target].active').text();
      let title = document.title.split(' | ');
      title[0] += ' > ' + tabHeading;
      title = title.join(' | ');

      history.pushState({}, title, url);
    }

  });

})(jQuery);